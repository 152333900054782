body {
    margin: 0;
    font-family: 'Suisse', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: Suisse;
    src: url(assets/font/SuisseThin.otf);
    font-weight: 100;
}

@font-face {
    font-family: Suisse;
    src: url(assets/font/SuisseLight.otf);
    font-weight: 300;
}

@font-face {
    font-family: Suisse;
    src: url(assets/font/SuisseRegular.otf);
    font-weight: 400;
}

@font-face {
    font-family: Suisse;
    src: url(assets/font/SuisseMedium.otf);
    font-weight: 500;
}

@font-face {
    font-family: Suisse;
    src: url(assets/font/SuisseBold.otf);
    font-weight: 700;
}

@font-face {
    font-family: Suisse;
    src: url(assets/font/SuisseBlack.otf);
    font-weight: 900;
}
